import styled from '@emotion/styled';
import {ChangeEvent, FC, FocusEvent} from 'react';
import {Icon} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Pattern, patterns} from 'source/utilities/patterns';
import {isFunction} from 'source/utilities/guards/types';
import {Input} from '../input';
import {Text, TextSize} from '../text';

const Wrapper = styled.div``;

const InputWrapper = styled.div<{isPassed?: boolean}>`
  display: flex;
  align-items: center;
  gap: ${({isPassed}) => (isPassed ? 20 : 0)}px;
  @media (max-width: 480px) {
    gap: unset;
  }
`;

const CrossWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 340px;
  @media (max-width: 480px) {
    max-width: 280px;
  }
`;

const StyledInput = styled(Input)`
  & > input {
    padding-right: 30px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

const TickWrapper = styled.div`
  transform: translateY(15px);
`;

export interface PatternInputProps {
  text?: string;
  gap?: number;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (value: FocusEvent<HTMLInputElement>) => void;
  setValue?: (value: string) => void;
  pattern?: RegExp | ((value?: string | null) => boolean);
  force?: InputProperties['force'];
  patternMessage?: string;
  forcedMessage?: string;
  patternId?: Pattern;
  className?: string;
  size?: TextSize;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  color?: string;
}

export const PatternInput: FC<PatternInputProps> = ({
  value,
  gap,
  text,
  className,
  force,
  setValue,
  onBlur,
  onFocus,
  onChange,
  pattern,
  patternMessage,
  forcedMessage,
  patternId,
  placeholder,
  size,
  disabled,
  required,
  color,
}) => {
  const mobile = useDeviceDetection('mobile');

  const checkPass = () => {
    if (isFunction(pattern)) {
      return pattern(value);
    }
    return pattern?.test(value);
  };

  const isPassed = checkPass();
  const showForcedMessage =
    forcedMessage && !isPassed && !patternId && value.length > 0;

  return (
    <Wrapper className={className}>
      <InputWrapper isPassed={isPassed}>
        <CrossWrapper className="cross-wrapper">
          {text ? (
            <LabelWrapper>
              <Text size={size || 'S'} style={{marginBottom: gap, color}}>
                {text}
              </Text>
              {mobile && isPassed && !disabled ? (
                <Icon name="tick" width={15} height={10} />
              ) : null}
            </LabelWrapper>
          ) : null}
          <StyledInput
            onChange={onChange}
            placeholder={placeholder}
            type="text"
            patternMessage={patternMessage}
            label=""
            force={force}
            disabled={disabled}
            pattern={patternId ? patterns.compatible(patternId) : undefined}
            onCrossClick={(e) => {
              if (!disabled) {
                e.stopPropagation();
                if (setValue) {
                  setValue('');
                }
              }
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            required={required}
            forcedMessage={showForcedMessage ? forcedMessage : undefined}
            value={value}
          />
        </CrossWrapper>
        <TickWrapper>
          {!mobile && isPassed && !disabled ? (
            <Icon name="tick" width={15} height={10} />
          ) : null}
        </TickWrapper>
      </InputWrapper>
    </Wrapper>
  );
};
