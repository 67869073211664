import ProcessEnv = NodeJS.ProcessEnv;

export const isServer = () => typeof window === 'undefined';

export const isClient = () => typeof window !== 'undefined';

const getEnvironment = (
  key: keyof ProcessEnv,
): ProcessEnv[keyof ProcessEnv] => {
  const environment = process.env;

  if (!(key in environment)) {
    throw new Error(`Environment: ${key} is not defined in .env`);
  }

  return environment[key];
};

export const YANDEX_API_KEY = getEnvironment('NEXT_PUBLIC_YMAPS_KEY');
export const ZHILIBYLI_URL = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_URL');
export const HOSTNAME = getEnvironment('NEXT_PUBLIC_HOSTNAME') || '';
export const ZHILIBYLI_PHONE = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PHONE');
export const HOTELIERS_KNOWLEDGE_BASE = getEnvironment(
  'NEXT_PUBLIC_HOTELIERS_KNOWLEDGE_BASE',
);
export const MAX_TOTAL_FILE_SIZE = getEnvironment(
  'NEXT_PUBLIC_MAX_TOTAL_FILE_SIZE',
);
export const BITRIX_SIDE_BUTTON_SCRIPT = getEnvironment(
  'NEXT_PUBLIC_BITRIX_SIDE_BUTTON_SCRIPT',
);
export const YM_ID = getEnvironment('NEXT_PUBLIC_YM_ID');
export const GOOGLE_TAG = getEnvironment('NEXT_PUBLIC_GOOGLE_TAG');

export const ROISTAT_ID = getEnvironment('NEXT_PUBLIC_ROISTAT_ID');

export const HOTJAR_ID = getEnvironment('NEXT_PUBLIC_HOTJAR_ID');

export const MIN_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MIN_BALANCE_WITHDRAW_AMOUNT',
);

export const MAX_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MAX_BALANCE_WITHDRAW_AMOUNT',
);
export const MAX_DESCRIPTION_VALUE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_MAX_DESCRIPTION_VALUE_LENGTH'),
);
